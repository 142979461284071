<template>
  <form @submit.prevent="handleSubmit" enctype="multipart/form-data" :class="{'p-invalid': invalid}">
    <div>
      <label for="image_uploads">Choose images to upload</label>
      <input
        type="file"
        id="image_uploads"
        @change="updateImageDisplay"
        accept=".jpg, .jpeg, .png"
        multiple
        style="display: none"
        capture="camera"
      />
    </div>
    <div class="preview">
      <p v-if="!files.length">No files currently selected for upload</p>
      <ol v-else>
        <li v-for="(file, index) in files" :key="index">
          <img :src="file.url" :alt="file.name" :title="file.name" />
          <p>{{ formatFileSize(file.size) }}</p>
        </li>
      </ol>
    </div>
  </form>
</template>

<script>

export default {
  props:{
    invalid: {
      type: Boolean,
      default: () => false,
    },
  },
  watch:{
    invalid(x){console.log(x)}
  },
  data() {
    return {
      files: [],
      filesBytes:[],
      validFileTypes: [
        "image/jpeg",
        "image/png",
        // добавьте другие форматы, если нужно
      ],
    };
  },
  methods: {
    updateImageDisplay(event) {
      this.$emit('getFiles',null)
      const selectedFiles = event.target.files;
      this.files = [];
      if (selectedFiles.length === 0) return;
      for (const file of selectedFiles) {
        // console.log(file)
        if (this.validFileType(file)) {
          this.files.push({
            name: file.name,
            size: file.size,
            url: URL.createObjectURL(file),
          });
          this.filesBytes.push(file)
        } else {
          alert(file.name+": Not a valid file type. Update your selection.");
        }
      }
      this.$emit('getFiles',this.filesBytes)
    },
    validFileType(file) {
      return this.validFileTypes.includes(file.type);
    },
    formatFileSize(size) {
      if (size < 1e3) {
        return size+"bytes";
      } else if (size >= 1e3 && size < 1e6) {
        return (size / 1e3).toFixed(1)+"KB";
      } else {
        return (size / 1e6).toFixed(1)+"MB";
      }
    },
    handleSubmit() {
      alert("Image uploaded!");
      this.files = []; // Очистить файлы после отправки


    },
  },
};
</script>

<style scoped>
html {
  font-family: sans-serif;
}

form {
  /*background: #ccc;*/
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ced4da;
  border-radius: 6px;
}

form ol {
  padding-left: 0;
}

form li,
div > p {
  background: #eee;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  list-style-type: none;
  border: 1px solid #ced4da;
  border-radius: 6px;
}

form img {
  height: 64px;
  order: 1;
}

form p {
  line-height: 32px;
  padding-left: 10px;
}

form label,
form button {
  background-color: #2C608C;
  color: white;
  padding: 8px 13px;
  border-radius: 6px;
  border: 1px ridge #ced4da;
  font-size: 0.8rem;
  height: auto;
}

form label:hover,
form button:hover {
  background-color: #4a76a8;
  color: white;
}

form label:active,
form button:active {
  background-color: #4a76a8;
  color: white;
}
/*.preview{*/
/*  display: flex;*/
/*}*/
</style>

