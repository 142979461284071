<script>
import DataView from 'primevue/dataview'
import tg from "../telegram/tg";
export default {
  components:{DataView},
  data(){
    return{
      friends:[],
      user:null,
      me:localStorage.mapa_me,
      s:localStorage.mapa_api
    }
  },
  mounted(){
   this.getFriends();
  },
  methods:{
    getFriends(){
      this.$http.post(this.s+'/app/friends/', {me:this.me, get_friends:'OK'})
        .then((res) => {
          console.log(res.data)
          this.friends=res.data;
          this.onScroll(this.friends)
      });
    },
    chat(user){
        localStorage.setItem("user", user);
        this.$emit('profile');
    },
    delFriend(user){
      this.$http.post(this.s+'/app/friends/', {me:this.me, nick:user, del_friend:'OK'}).then((res) => {
        console.log(res)
          if (res.ok===true){
            this.getFriends()
          }
        });
    },
    isInViewport(user) {
      const element = document.getElementById(`user-${user.id}`);
      if (!element) return false;
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    async onScroll(items) {
      const visibleGroups = items.filter(user => this.isInViewport(user));
      for (const user of visibleGroups) {
        if (!user.photoLoaded) {
          this.$set(user, 'photoLoaded', true);
          const photoUrl = await tg.getUserPhoto(await tg.getUser(user.nick))
          this.$set(user, 'image', photoUrl);
        }
      }
    },
  },
}
</script>

<template>
<!--  <div>-->
<!--    <div class="content-list">-->
<!--      <mu-list>-->
<!--        <mu-sub-header><h4>Featured ☆</h4></mu-sub-header>-->
<!--        <mu-list-item :key="friend.id" v-for="friend in friends" @click="chat(friend.nick)">-->
<!--          <mu-avatar :src="s+friend.image" slot="leftAvatar" />-->
<!--          <h4 class="user">{{friend.nick}}</h4>-->
<!--          <div slot="right" @click.stop="delFriend(friend.nick)" class="del_button">X</div>-->
<!--        </mu-list-item>-->
<!--      </mu-list>-->
<!--      <mu-divider/>-->
<!--    </div>-->
<!--  </div>-->
<div class="main">


<!--  <DataView :value="friends">-->
<!--    <template #list="user">-->
<!--      <div onmouseover="this.style.backgroundColor='#4a76a8'" onmouseout="this.style.backgroundColor='white'" style="width: 100%;border: 2px solid darkgrey;" class="user-item">-->
<!--        <div @click="chat(user.data.nick)" style="display: flex; align-items: center; justify-content: space-between; max-width: 800px; margin: 0 auto;cursor: pointer">-->
<!--          <img :src="s+user.data.image" alt="User Photo" class="user-photo">-->
<!--          <div class="user-nick">{{ user.data.nick }}</div>-->
<!--          <Button @click.stop="delFriend(user.data.nick)" class="p-button-danger p-button-rounded p-button-sm p-button-raised delete-button">❌</Button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </template>-->
<!--  </DataView>-->

  <div class="dialog-list">
    <div v-for="user of friends" :key="user.id" :id="'user-' + user.id">
      <div class="user-item">
        <div @click="chat(user.nick)" class="user-item-container">
          <img :src="user.image" alt="User Photo" class="user-photo">
          <div class="user-nick">{{ user.nick }}</div>
          <Button @click.stop="delFriend(user.nick)" class="p-button-danger p-button-rounded p-button-sm p-button-raised delete-button">❌</Button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="friends.length===0" class="zero_data">
    <p>Нету избранных</p>
  </div>
</div>
</template>

<style scoped>

</style>
