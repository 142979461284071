// let socket = new WebSocket(localStorage.socket);
//
// socket.onclose = function(event) {
//   if (event.wasClean) {
//     console.log(`Соединение закрыто чисто, код=${event.code} причина=${event.reason}`);
//   } else {
//     console.log('Соединение прервано')
//     console.log(event)
//     // socket.onopen = function(e) {
//     //   console.log("Соединение установлено заново");
//     // };
//   }
// };
//
// socket.onerror = function(error) {
//   console.log(error);
// };

let socket;

function connectWebSocket() {
  return new Promise((resolve, reject) => {
    socket = new WebSocket(localStorage.socket);

    socket.onopen = function() {
      console.log('Соединение установлено');
      resolve(socket); // Успешное соединение
    };

    // socket.onmessage = function(event) {
    //   console.log('Сообщение от сервера:', event.data);
    // };

    socket.onclose = function(event) {
      console.log('Соединение закрыто', event);
      // Попробуйте восстановить соединение через 1 секунду
      setTimeout(() => connectWebSocket().then(resolve).catch(reject), 1000);
    };

    socket.onerror = function(error) {
      console.error('Ошибка WebSocket:', error);
      reject(error); // Ошибка соединения
    };
  });
}

function closeWebSocket() {
  if (socket && socket.readyState === WebSocket.OPEN) {
    socket.close();
    console.log('Соединение закрыто вручную');
  } else {
    console.log('Соединение уже закрыто или не установлено');
  }
}

export { connectWebSocket, closeWebSocket, socket };
