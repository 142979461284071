//import 'babel-polyfill';
//import MuseUI from 'muse-ui'
//import 'muse-ui/dist/muse-ui.css'
// import 'primeicons/primeicons.css'
import { createPinia, PiniaVuePlugin, setMapStoreSuffix } from 'pinia'
import Vue from 'vue'
import App from './App.vue'
import VueResource from 'vue-resource';
import i18n from './i18n';
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/lara-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';


import Button from "primevue/button/Button";
Vue.component('Button', Button)
import InputText from "primevue/inputtext/InputText";
import './registerServiceWorker'
Vue.component('InputText', InputText)

Vue.use(PrimeVue, {ripple: true});
Vue.use(VueResource);
Vue.http.options.emulateJSON = true;
Vue.use(PiniaVuePlugin)
// setMapStoreSuffix('')
// Vue.prototype.$bus = new Vue()
const pinia = createPinia()
new Vue({
  i18n,
  el: '#app',
  pinia,
  render: h => h(App)
});

//Vue.use(MuseUI);//2.1.0
// const http = reactive({
//   post(url, data) {
//     return axios.post(url, new URLSearchParams(data).toString(), {
//       headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
//     }).then(response => response.data);
//   }
// });
