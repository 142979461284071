<template>
  <div>
    <div id="map"></div>
    <div id="context-menu" class="context-menu"></div>
    <div class="map-title" v-if="room!==null" @click="goToGlobal">{{room_title}}</div>
    <Dialog :header="$t('groups.attention')" :visible.sync="dialog_goToGlobalMap" :modal="true">
      {{$t('tg.dialog_goToGlobalMap')}}
      <template #footer>
        <Button label="ОК" autofocus @click="dialog_goToGlobalMap=false"/>
      </template>
    </Dialog>

<!--    <div v-if="showMenu" class="context-menu" :style="{ top: menuPosition.y + 'px', left: menuPosition.x + 'px' }">-->
<!--      <ul>-->
<!--        <li class="context-menu-item" @click="deleteStaticMarker">Удалить маркер</li>-->
<!--      </ul>-->
<!--    </div>-->

    <Dialog class="dialog_marker" header="Создать маркер" :visible.sync="create_marker" :modal="true" close-on-escape>
      <label>Название</label>
      <div class="field">
        <InputText  :class="{'p-invalid': v$.marker_title.$invalid && sub}" v-model="v$.marker_title.$model"/>
      </div>
      <label>Широта</label>
      <div class="field">
        <InputText :class="{'p-invalid': v$.marker_lat.$invalid && sub}" v-model="v$.marker_lat.$model"/>
      </div>
      <label>Долгота</label>
      <div class="field">
        <InputText :class="{'p-invalid': v$.marker_lng.$invalid && sub}" v-model="v$.marker_lng.$model"/>
      </div>

      <div v-if="files!==null">
        <label>Текст</label>
        <div class="field">
          <Textarea rows="5" cols="30"  :class="{'p-invalid': v$.marker_text.$invalid && sub}" v-model="v$.marker_text.$model"/>
        </div>
      </div>


      <div class="field">
        <file-upload @getFiles="(files)=>{this.files = files}" v-model:invalid="v$.files.$invalid && sub" v-if=""/>
<!--        <div v-if="!isMobile">Загрузка фото доступна только с мобильного устройства.</div>-->
      </div>

      <template #footer>
        <Button label="Закрыть" autofocus @click="create_marker=false"/>
        <Button label="ОК" autofocus @click="sendStaticMarker"/>
      </template>
    </Dialog>
  </div>
</template>

<script>
import '../../public/assets/leaflet/leaflet.css'
import '../../public/assets/leaflet/leaflet.js'
import { PruneCluster, PruneClusterForLeaflet } from 'exports-loader?PruneCluster,PruneClusterForLeaflet!prunecluster/dist/PruneCluster.js'
import tg from "../telegram/tg";
import {socket, connectWebSocket} from "../socket";
import {TgMapStore} from "../store";
import Dialog from "primevue/dialog";
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, maxLength } from '@vuelidate/validators';
import OverlayPanel from "primevue/overlaypanel";
import fileUpload from "../components/fileUpload.vue";
import Textarea from 'primevue/textarea';
import "../../public/assets/leaflet/draw/getdraw";
import "../../public/assets/leaflet/leaflet-search.js"
// import '../../public/assets/leaflet/leaflet.css'
// import '../../public/assets/leaflet/leaflet.js'
// import '../../public/assets/leaflet/esri/esri-leaflet'
// import '../../public/assets/leaflet/esri-geocoder/esri-leaflet-geocoder.css'
// import '../../public/assets/leaflet/esri-geocoder/esri-leaflet-geocoder'
// import "../../public/assets/code"
export default {
  components:{Dialog,OverlayPanel,fileUpload,Textarea},
  setup() {
    const tgMapStore = TgMapStore()
    return {tgMapStore,v$:useVuelidate()}
  },
  data(){
    return {
      me:localStorage.mapa_me,
      status:localStorage.mapa_status,
      map:null,
      tileLayer:localStorage.tileLayer!==undefined?localStorage.tileLayer:'osm',
      mypos: localStorage.mapa_mypos!==undefined ? JSON.parse(localStorage.mapa_mypos) : true,
      lat:localStorage.getItem("lat")!==undefined ? localStorage.getItem("lat") : 53.9045398,
      lng:localStorage.getItem("lng")!==undefined ? localStorage.getItem("lng") : 27.5615244,
      zoom:localStorage.getItem("zoom")!==undefined ? localStorage.getItem("zoom") : 10,
      duration: 2000, // Длительность анимации в миллисекундах
      startTime: null,
      leafletView: new PruneClusterForLeaflet(),
      markers:[],
      room:localStorage.group_map_id!==undefined ? localStorage.group_map_id:null,
      room_title:localStorage.group_title!==undefined ? localStorage.group_title:null,
      create_marker:false,
      marker_title:null,
      marker_lat:null,
      marker_lng:null,
      marker_text:null,
      sub:false,
      marker_id:null,
      showMenu: false,
      menuPosition: { x: 0, y: 0 },
      files:null,
      drawnItems:null,
      lastUpdateTime:0,
      intervalLocation:5000,
      dialog_goToGlobalMap:false,
    }
  },
  validations(){
    return{
      marker_title:{required, maxLength: maxLength(30)},
      marker_lat:{required},
      marker_lng:{required},
      marker_text:{},
      files:{max_col(){
        try{
          return this.files.length<=30
        }catch{
          return this.files === null
        }
      }}
    }
  },
  created() {},
  computed: {isMobile() {return /Mobi|Android/i.test(navigator.userAgent);}},
  mounted() {
    const t = this
    if (socket) {
      console.log("Соединение уже установлено");
      start()
    } else{
      connectWebSocket().then(socket => {
        start()
      })
    }
    function start() {
      t.initMap()
      if(t.me){
        t.initLocation()
        t.getMarkers()
        if(localStorage.group_map_id){
          t.getStaticMarkers()
          t.getDraws()
        }
      }

      socket.onmessage = function (event) {
        let msg = JSON.parse(event.data);
        // console.log(msg)
        if(msg.type===0){
          t.animateMarkers(t.leafletView.Cluster._markers, msg);
        }else if(msg.type===1){
          t.createMarker(msg)
        }else if(msg.type===2 && msg.photos[0].properties.owner !== t.me){
          t.createLayersFromJson(msg.photos)
        }else if(msg.type===3){
          console.log('close')
          //t.deleteMatker(msg)
        }
      };
    }

    setTimeout(function(){
      // console.log(this.markers)
      console.log(this.leafletView.Cluster._markers)
    }.bind(this),1000);

    // console.log('room: '+this.room)
    // const t = this
    // t.initMap()
    // if(localStorage.group_map_id){
    //   this.getStaticMarkers()
    //   this.getDraws()
    // }
    // t.getMarkers()
    // setTimeout(function(){t.initLocation()}.bind(t),1000)
    // console.log(socket.readyState)
    //-----------------------------------------------------------------------
    // setTimeout(function(){this.initLocation()}.bind(this),2000)
    // setTimeout(function(){this.getMarkers()}.bind(this),2000)
    // setTimeout(function(){
    //   this.map.stopLocate()
    //   this.map.locate({setView: localStorage.getItem('getpos')===undefined?this.mypos:false, maxZoom: localStorage.getItem("zoom"), watch:true, enableHighAccuracy:true})
    // }.bind(this),3000)
    //
    // setTimeout(function(){
    //   if(localStorage.group_map_id!==undefined && localStorage.group_map_id!==null){
    //     this.getStaticMarkers()
    //   }
    // }.bind(this),4000)
    //
    // setTimeout(function(){
    //   if(this.tgMapStore.online === false){
    //     socket.send(JSON.stringify({me:this.me, lat:'', lng:''}))
    //     this.tgMapStore.toggleOnline()
    //   }
    // }.bind(this),1000);
    //
    // if(this.me === 'atkisai' && !this.tgMapStore.online)
    //   setTimeout(function(){this.pushFakeUsers()}.bind(this),4000);
    //
    // this.tgMapStore.toggleOnline()
  },
  methods: {
    goToGlobal(){
      localStorage.removeItem('group_map_id')
      this.tgMapStore.cleanUsers()
      window.location.reload()
    },
    createLayersFromJson(data) {
      const layerGroup = L.layerGroup();
      data.forEach((geo, id) => {
        L.geoJSON(geo, {
          pointToLayer: (feature, latlng) => {
            if (feature.properties.radius) {
              return new L.Circle(latlng, feature.properties.radius);
            } else {
              return new L.Marker(latlng);
            }
          },
          onEachFeature: (feature, layer) => {
            if(feature.properties.owner === this.me){
              this.drawnItems.addLayer(layer);
            }else{
              layerGroup.addLayer(layer);
            }
          },
        });
      });
      layerGroup.addTo(this.map)
    },
    pushFakeUsers(){
      function random(latOrLng) {
        const lat = 54.2279000;
        const lng = 28.5050000;
        if (latOrLng === "lat") {
          return (lat - 1) + Math.random() * ((lat + 1) - (lat - 1));
        } else {
          return (lng - 1) + Math.random() * ((lng + 1) - (lng - 1));
        }
      }
      const users = []
      for (let i = 0; i<1000;i++){
        users.push(
          {me:'fake-user-'+i,lat:random('lat'),lng:random('lng')}
        )
      }
      console.log(users)
      function getRandomUser(users) {
        const randomIndex = Math.floor(Math.random() * users.length);  // Генерируем случайный индекс
        return users[randomIndex];  // Возвращаем элемент списка по случайному индексу
      }

      function pushUser(){
        const user = getRandomUser(users)
        // console.log(user)
        socket.send(JSON.stringify(user))
      }
      // setTimeout(function(){
      //   setInterval(pushUser, 1000);
      // },5000);
      setInterval(pushUser, 500);
      // pushUser()
    },
    getDraws(){
      this.$http.post(localStorage.mapa_api+'/tg/draw/', {group_id:localStorage.group_map_id, get_draws:"OK"}).then((res) => {
        // console.log(res)
        if (res.ok === true && res.data!==null) {
          res.data.forEach(item=>item.data.properties.id=item.id)
          const dataList=res.data.map(item=>item.data)
          console.log(dataList)
          this.createLayersFromJson(dataList)
        }
      });
    },
    initMap() {
      if(this.lat === null || this.lng ===  null){
        this.lat = 53.9045398
        this.lng = 27.5615244
      }
      const osm = L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors', name: 'osm' });
      const google = L.tileLayer('http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}', {attribution: 'google', name: 'google'});
      this.map = L.map ('map'). setView ([this.lat,this.lng], localStorage.getItem("zoom"));
      this.map.setGeocoder('Nominatim' /* , { ... Geocoder options ... } */);
      this.map.addControl(L.control.search({ position: 'bottomright' }));

      // Восстанавливаем выбор слоя из localStorage при загрузке
      if (this.tileLayer === 'osm') {
        osm.addTo(this.map);
      } else {
        google.addTo(this.map);
      }
      this.map.on("moveend", (e)=>{
        let zoom = this.map.getZoom();
        localStorage.setItem("zoom", zoom);
        let center = this.map.getCenter();
        localStorage.setItem("lat", center.lat);
        localStorage.setItem("lng", center.lng);
      });
      this.drawnItems = L.featureGroup().addTo(this.map);
      const baseLayers = {'osm': osm, 'google': google}
      const overlays = {}
      const options = { position: 'topright', collapsed: false }
      if(this.room!==null){
        overlays['draw'] = this.drawnItems
      }
      const layerControl = L.control.layers(baseLayers, overlays, options).addTo(this.map);
      const button = L.control({position: 'bottomright'});
      button.onAdd = function (map) {
        const div = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
        const link = L.DomUtil.create('a', 'leaflet-control-zoom-in svg-map'); // Добавляем несколько классов к <a>
        link.href = '#'; // Устанавливаем атрибут href
        const span = L.DomUtil.create('span')
        span.innerHTML =
          `
           <svg width="24" height="24" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" style="color:#b3b3b3" role="img">
    <g fill="#b3b3b3">
        <path fill="currentColor" d="M4 12.983a1 1 0 0 0 .629.945l9.601 3.841l3.841 9.602A1 1 0 0 0 19 28h.016a1 1 0 0 0 .924-.658l8-22a1 1 0 0 0-1.282-1.283l-22 8a1.001 1.001 0 0 0-.658.925Z"/>
    </g>
</svg>

          `
        link.onclick = function (e) {
          e.preventDefault();
          this.map.setView([this.lat, this.lng]);
        }.bind(this);
        link.appendChild(span)
        div.appendChild(link)
        return div;
      }.bind(this);
      button.addTo(this.map);


      // Слушаем события добавления и удаления слоев
      // overlayadd
      this.map.on('layeradd', (e) => {
        if(e.layer.options.name!==undefined)
          localStorage.tileLayer=e.layer.options.name
      });
      // overlayremove
      // this.map.on('layerremove', (e) => {
      //   if (e.layer.options.name === 'google') {
      //     localStorage.removeItem('tileLayer'); // Удаляем, если Google был убран
      //     // Можно установить OSM по умолчанию, если это необходимо
      //     osm.addTo(this.map);
      //   }
      // });
      if(localStorage.group_map_id) {
        const button = L.control({position: 'topleft'});
        button.onAdd = function (map) {
          const div = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
          const link = L.DomUtil.create('a', 'leaflet-control-zoom-in svg-map'); // Добавляем несколько классов к <a>
          link.href = '#'; // Устанавливаем атрибут href
          const span = L.DomUtil.create('span')
          span.innerHTML =
            `
             <svg width="20" height="20" viewBox="0 0 24 24" style="color:currentColor" xmlns="http://www.w3.org/2000/svg" role="img">
                <g fill="currentColor">
                    <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path stroke-dasharray="56" stroke-dashoffset="56" d="M3 21L4.99998 15L16 4C17 3 19 3 20 4C21 5 21 7 20 8L8.99998 19L3 21">
                            <animate fill="freeze" attributeName="stroke-dashoffset" dur="0.6s" values="56;0"/>
                        </path>
                        <g stroke-dasharray="6" stroke-dashoffset="6">
                            <path d="M15 5L19 9">
                                <animate fill="freeze" attributeName="stroke-dashoffset" begin="0.6s" dur="0.2s" values="6;0"/>
                            </path>
                            <path stroke-width="1" d="M6 15L9 18">
                                <animate fill="freeze" attributeName="stroke-dashoffset" begin="0.6s" dur="0.2s" values="6;0"/>
                            </path>
                        </g>
                    </g>
                    <path fill="currentColor" fill-opacity="0" d="M17 4H20V7L9 18L6 15L17 4Z">
                        <animate fill="freeze" attributeName="fill-opacity" begin="0.8s" dur="0.15s" values="0;0.3"/>
                    </path>
                </g>
            </svg>
          `
          link.onclick = function (e) {
            e.preventDefault(); // Отменяем действие по умолчанию
            // alert('Ссылка нажата!');
            if (drawControl._map) {
              map.removeControl(drawControl); // Удаляем панель рисования
              localStorage.removeItem('draw')
            } else {
              map.addControl(drawControl); // Добавляем панель рисования
              localStorage.draw = "1"
            }
          };
          link.appendChild(span)
          div.appendChild(link)
          return div;
        };
        button.addTo(this.map);

        const drawControl = new L.Control.Draw({
          edit: {
            featureGroup: this.drawnItems,
            poly: {
              allowIntersection: false
            }
          },
          draw: {
            polygon: {
              allowIntersection: false,
              showArea: true
            }
          }
        })
        if (localStorage.draw) {
          this.map.addControl(drawControl);
        }
        // Сохранение нарисованных фигур
        this.map.on(L.Draw.Event.CREATED, (event) => {
          let layer = event.layer
          console.log(layer)
          let radius
          if (layer instanceof L.Circle) {
            radius = layer.getRadius();
            console.log("Это круг с радиусом:", radius);
          } else if (layer instanceof L.Marker) {
            console.log("Это маркер");
            // console.log(layer._latlng)
            this.create_marker=true
            this.marker_lat=layer._latlng.lat
            this.marker_lng=layer._latlng.lng
            return;
          } else if (layer instanceof L.Polygon) {
            console.log("Это многоугольник");
          } else {
            console.log("Это другой тип слоя");
          }
          this.drawnItems.addLayer(layer);
          layer = layer.toGeoJSON()
          if (radius) {
            layer.properties.radius = radius;
          }
          layer.properties.owner = this.me;// t is not defained
          this.$http.post(localStorage.mapa_api+'/tg/draw/', {me:this.me, group_id: localStorage.group_map_id,data:JSON.stringify(layer), add_draw:'OK'}).then((res) => {
            console.log(res.data)
            layer.id = res.data
            // layer.feature = {properties:{id:res.data}}
            layer.properties.id = res.data;
            console.log(layer)
            console.log(this.drawnItems)
            const data = {
              room: this.room,
              photos: [layer],
              type: 2,
            }
            socket.send(JSON.stringify(data))
          });
        });
        // Отслеживание события deletestop
        this.map.on('draw:deleted', function (e) {
          console.log(e)
          const deletedLayersIds = Object.values(e.layers._layers).map(layer=>layer.feature!==undefined?layer.feature.properties.id:null)
          console.log(deletedLayersIds)
          if(deletedLayersIds[0]!==null)
            this.$http.post(localStorage.mapa_api+'/tg/draw/', {me:this.me, ids:deletedLayersIds, del_draw_selected:'OK'}).then((res) => {
              console.log(res)
            });
          else
            alert('Перезагрузите страницу для удаления фигуры')
          // let remainingLayers = this.drawnItems.getLayers();
          // if (remainingLayers.length === 0) {
          //   console.log('Все слои были удалены через инструмент удаления');
          //   this.$http.post(localStorage.mapa_api+'/tg/draw/', {me:this.me, del_draw_all:'OK'}).then((res) => {
          //     console.log(res)
          //   });
          // }
        }.bind(this));
      }
      // this.map = L.map ('map'). setView ([this.lat,this.lng], localStorage.getItem("zoom"));//[53.9045398,27.5615244]
      // this.tileLayer = L.tileLayer ('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',{
      //     maxZoom: 19,
      //     attribution: '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      //   }
      // );
      // this.tileLayer.addTo(this.map);
      // drawnItems = L.featureGroup().addTo(map);
    },
    initLocation() {
      this.map.stopLocate()
      this.map.locate({setView: localStorage.getItem('getpos')===undefined?this.mypos:false, maxZoom: localStorage.getItem("zoom"), watch:true, enableHighAccuracy:true});//watch:true - fo real time, setView:true - see locate, maxZoom: this.zoom
      if(this.room===null)
        this.map.on('locationfound', (e)=>{
          const currentTime = Date.now()
          // Проверяем, прошло ли 5 секунд с последнего обновления
          if (currentTime - this.lastUpdateTime >= this.intervalLocation) {
            // Обновляем время последнего обновления
            this.lastUpdateTime = currentTime
            // console.log(JSON.stringify({lat: e.latlng.lat, lng: e.latlng.lng, me: this.me, type: 0}))
            socket.send(JSON.stringify({
              lat: e.latlng.lat, lng: e.latlng.lng, me: this.me, type: 0,status:this.status
            }))
            this.lat = e.latlng.lat
            this.lng = e.latlng.lng
          }
        });
      else
        this.map.on('locationfound', (e)=>{
          const currentTime = Date.now()
          if (currentTime - this.lastUpdateTime >= this.intervalLocation) {
            this.lastUpdateTime = currentTime
            socket.send(JSON.stringify({
              room: this.room, lat: e.latlng.lat, lng: e.latlng.lng, me: this.me, type: 0,status:this.status
              //id: Math.floor(Math.random() * 1000000)
            }))
            this.lat = e.latlng.lat
            this.lng = e.latlng.lng
          }
        });
    },
    getStaticMarkers(){
      // this.map.on('dblclick', (e)=>{
      //   this.create_marker=true
      //   this.marker_lat=e.latlng.lat
      //   this.marker_lng=e.latlng.lng
      // })
      this.$http.post(localStorage.mapa_api+'/tg/markers/', {group_id:localStorage.group_map_id, get_markers:"OK"}).then((res) => {
        // console.log(res)
        if (res.ok === true && res.data!==null) {
          for(let user of res.data){
            this.createMarker(user)
          }
          // if(this.tgMapStore.users.length===0){
          //   for(let user of res.data){
          //     this.createMarker(user)
          //   }
          // }else{
          //   for(let user of res.data){
          //     // const res = this.tgMapStore.users.filter(item=>item.me===user.me)
          //     if(this.tgMapStore.users.filter(item=>item.me===user.me).length===0){
          //       this.createMarker(user)
          //       this.tgMapStore.addUser(user)
          //     }
          //   }
          // }
        }
      });
    },
    deleteStaticMarker(marker){
      console.log(marker)
    },
    async sendStaticMarker(){
      const t = this
      // if(t.files!==null && t.room!==null){
      //   tg.sendPhotos(t.files, t.room)
      // }
      t.sub=true
      if(!t.v$.marker_title.$invalid && !t.v$.marker_lat.$invalid && !t.v$.marker_lng.$invalid && !t.v$.files.$invalid){
        let photos
        if(t.marker_text===null)
          t.marker_text="Здесь могла быть ваша реклама!"
        if(t.files!==null){
          photos = await tg.sendPhotos(t.files, t.room, t.marker_text)
          console.log(photos)
        }else{
          photos = []
        }
        const data = {
          room: t.room,
          lat: t.marker_lat,
          lng: t.marker_lng,
          me: t.marker_title,
          type: 1,
          photos:photos,
        }
        socket.send(JSON.stringify(data))
        t.create_marker=false
        this.$http.post(localStorage.mapa_api+'/tg/markers/', {
          me: t.me,
          title:t.marker_title,
          lat: t.marker_lat,
          lng: t.marker_lng,
          group_id:localStorage.group_map_id,
          add_marker:"OK",
          items: photos,
        }).then((res) => {
          console.log(res)
        });
      }
    },
    getMarkers() {
      const t = this
      // t.leafletView = new PruneClusterForLeaflet()
      //console.log(t.tgMapStore.users)
      for(let user of t.tgMapStore.users){
        // if(user.marker===false)
        t.createMarker(user)
        // else
        //   t.createStaticMarker(user)
      }
      //socket.onmessage
      t.map.addLayer(t.leafletView);
    },
    async createMarker(user){
      console.log(user)
      const t = this
      let marker = new PruneCluster.Marker(user.lat, user.lng);
      marker.data.user = user.me
      // marker.data.info = user.me
      marker.data.info = user.status === "" ? user.me : user.status;
      let url = ''
      if(user.me.includes("fake-user")){
        url = "marker-icon.png"
        marker.data.icon = L.icon({
          iconUrl: url,
          iconSize: [30,53],
        });
      }
      else if(user.type===1 || user.group_id!==undefined){
        marker.data.marker = true
        marker.data.id = user.id
        marker.data.owner = user.profile_name
        marker.data.photos = user.photos
        url = "marker-icon.png"
        marker.data.icon = L.icon({
          iconUrl: url,
          iconSize: [30,53],
        });
      }
      else {
        url = await tg.getUserPhoto(await tg.getUser(user.me))
        marker.data.icon = L.icon({
          iconUrl: url,
          iconSize: [40, 40],
        });

      }

      t.leafletView.PrepareLeafletMarker = function (leafletMarker, marker) {
        // console.log(marker)
        leafletMarker.setIcon(marker.icon);
        leafletMarker.bindTooltip(marker.info);
        leafletMarker.bindPopup(marker.info);

        if(marker.marker){
          const contextMenu = document.getElementById('context-menu');
          leafletMarker.off('contextmenu')
          leafletMarker.on('contextmenu', function (e) {
            // console.log(event)
            // this.showMenu = true; // Показываем меню
            // this.menuPosition = { x: e.originalEvent.pageX, y: e.originalEvent.pageY }; // Устанавливаем позицию меню
            contextMenu.style.display = 'block';
            contextMenu.style.left = e.originalEvent.clientX + 'px';
            contextMenu.style.top = e.originalEvent.clientY + 'px';
            contextMenu.dataset.lat = e.target.getLatLng().lat;
            contextMenu.dataset.lng = e.target.getLatLng().lng;
          });
          window.addEventListener('click', function() {
            contextMenu.style.display = 'none';
            // this.showMenu = false;
          });
          contextMenu.innerHTML = "<div class=\"context-menu-item\" onclick=\"this.deleteStaticMarker('lol')\">Удалить маркер</div>\n"
        }
        leafletMarker.off('click')
        leafletMarker.on('click', function () {
          if (marker.user !== t.me) {
            if(marker.marker){
              t.tgMapStore.addMarker(marker)
              t.$emit('marker');
            }else if (localStorage.getItem("openHot") === "0") {
              localStorage.setItem("user", marker.user);
              t.$emit('profile');
            }else{
              window.open('https://t.me/'+marker.user, '_blank');
            }
          } else {
            console.log(marker)
            t.$emit('myprofile');
          }
        });
      }.bind(this)
      // t.markers.push(marker);
      t.leafletView.RegisterMarker(marker);

      // if(marker.data.user === t.me){
      //   clickAnimation(marker)
      //   function clickAnimation(m){
      //     // let t = this
      //     t.map.on('click', function(event) {
      //       // let targetLatLng = [event.latlng.lat, event.latlng.lng];
      //       // let currentLatLng = [m.position.lat, m.position.lng];
      //       if (m.data.user === t.me && t.mypos) {
      //         m.Move(event.latlng.lat, event.latlng.lng)
      //         t.leafletView.ProcessView()
      //         // requestAnimationFrame(t.animateMarkerWrapper(m, null, currentLatLng, targetLatLng));
      //       }else{
      //         m.Move(event.latlng.lat, event.latlng.lng)
      //         t.leafletView.ProcessView()
      //         // requestAnimationFrame(t.animateMarkerWrapperNoCamera(m, null, currentLatLng, targetLatLng));
      //       }
      //       socket.send(JSON.stringify({lat: event.latlng.lat, lng: event.latlng.lng, me: t.me}))
      //     });
      //   }
      // }

      t.leafletView.ProcessView();
    },
    deleteMatker(user){
      // const marker = this.markers.filter(item => item.data.user===user.me)[0]
      const marker = this.leafletView.Cluster._markers.filter(item => item.data.user===user.me)[0]
      this.leafletView.RemoveMarkers([marker])
      // this.markers = this.markers.filter(item => item.me!==user.me)
      this.tgMapStore.delUser(user)
      this.leafletView.ProcessView()
      console.log(this.markers)
    },
    async animateMarkers(markers, msg) {
      const t = this
      let promises = markers.map(async m => {
        if (m.data.user === msg.me) {
          t.tgMapStore.updateUser(msg)
          let currentLatLng = [m.position.lat, m.position.lng];
          let targetLatLng = [msg.lat, msg.lng];
          if (msg.me === t.me && t.mypos) {
            // m.Move(msg.lat, msg.lng)
            // t.leafletView.ProcessView()
            requestAnimationFrame(t.animateMarkerWrapper(m, null, currentLatLng, targetLatLng));
          } else {
            // m.Move(msg.lat, msg.lng)
            // t.leafletView.ProcessView()
            requestAnimationFrame(t.animateMarkerWrapperNoCamera(m, null, currentLatLng, targetLatLng));
          }
          return true;
        }
        return false;
      });
      let results = await Promise.all(promises);
      // Проверяем, все ли результаты равны false
      if (!results.some(result => result === true)) {
        await t.createMarker(msg)
        t.tgMapStore.addUser(msg)
      }
    },
    animateMarkerWrapper(marker, startTime, currentLatLng, targetLatLng) {
      let t = this;
      return function (timestamp) {
        if (!startTime) {
          startTime = timestamp;
        }
        let progressFraction = (timestamp - startTime)/t.duration
        if (progressFraction < 1) {
          // let interpolatedLatLng = [
          //   currentLatLng[0] + (targetLatLng[0] - currentLatLng[0]) * progressFraction,
          //   currentLatLng[1] + (targetLatLng[1] - currentLatLng[1]) * progressFraction
          // ];
          const interpolatedLat = currentLatLng[0] + (targetLatLng[0] - currentLatLng[0]) * progressFraction
          const interpolatedLng = currentLatLng[1] + (targetLatLng[1] - currentLatLng[1]) * progressFraction
          marker.position.lat = interpolatedLat
          marker.position.lng = interpolatedLng
          marker.data.info =
          t.map.setView([interpolatedLat, interpolatedLng], t.map.getZoom());
          t.leafletView.ProcessView();
          requestAnimationFrame(t.animateMarkerWrapper(marker, startTime, currentLatLng, targetLatLng));
        }
      };
    },
    animateMarkerWrapperNoCamera(marker, startTime, currentLatLng, targetLatLng) {
      let t = this;
      return function (timestamp) {
        if (!startTime) {
          startTime = timestamp;
        }
        let progressFraction = (timestamp - startTime)/t.duration
        if (progressFraction < 1) {
          marker.position.lat = currentLatLng[0] + (targetLatLng[0] - currentLatLng[0]) * progressFraction;
          marker.position.lng = currentLatLng[1] + (targetLatLng[1] - currentLatLng[1]) * progressFraction;
          t.leafletView.ProcessView();
          requestAnimationFrame(t.animateMarkerWrapperNoCamera(marker, startTime, currentLatLng, targetLatLng));
        }
      };
    },
  },
  beforeDestroy() {
    //socket.close()
    this.map.off('locationfound');
  },
}
</script>

<style scoped="">
@import url('../../public/assets/leaflet/prunecluster/LeafletStyleSheet.css');

#map {
  /*height: 94.3%;*/
  height: calc(100% - 50px);
  /*height: 100%;*/
  width: 100vw;
}

div#size, a#delete {
  position: absolute;
  right: 1em;
  top: 1em;
  background: white;
  color: black;
  padding: 0.4em;
  border-radius: 4px;
  z-index: 500;
}

.map-title {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 1000; /* Чтобы текст был выше остальных элементов */
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  /*border-bottom: 2px solid #ccc;*/
}
.map-title:hover{
  background-color: red;
}

</style>
