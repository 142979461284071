import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
const messages = {
  'en':{
    auth:{
      reg:'Registration',
      login:'Login',
      nick:'Nick',
      pass:'Password',
      confirm:'Confirm',
      send:'Send',
      valid:{
        nick:'Enter nick',
        pass:'Enter password min. 8 characters',
        confirm:'Password mismatch',
      },
      tg:{
        login_button:'Sign in with Telegram',
        phone:'Phone number',
        code:'Code from telegram',
      },
      dialog:"This site uses your location and shows it to other users! You can show your location only within groups to trusted people! The exact location will only be when using GPS! Site does not transfer your data to 3rd parties! Site does not show ads and we will always welcome your donations! Site is under development, please forgive any shortcomings."
    },
    myprofile:{
      status:'Status',
      site:'Site',
      site_title:'If a site is listed here, it opens when other users click on your avatar on the map.',
      ava:'Download avatar',
      delete_user:'Delete user',
      delete_ava:'Delete avatar',
      settings:{
        settings:'Settings map',
        centered:'I\'m centered on the map',
        open_site:'Open user sites from the map',
      },
    },
    profile:{
      delete_user:'Delete from Featured',
      add_user:'Add to Featured',
      on_map:'View on map',
      to_site:'Go to site',
    },
    groups:{
      my_groups:'My groups',
      groups:'Groups',
      users:'Users',
      attention:'Attention!',
      dialog:'Raise your user dialogues and chats in the telegram app if you don\'t see them here. It means there hasn\'t been any activity there for a long time and we can\'t get them. Just write in your chat or user dialogue. Thank you for understanding!',
      add_group:'Add group to mapa',
      del_group:'Delete group from mapa',
      exit_map:'Exit the map',
      invite:'Invite a friend to mapa',
    }
  },
  'ru':{
    auth:{
      reg:'Регистрация',
      login:'Войти',
      nick:'Ник',
      pass: 'Пароль',
      confirm:'Подтверждение',
      send:'Отправить',
      valid:{
        nick:'Введите ник',
        pass:'Введите пароль мин. 8 символов',
        confirm:'Пароли не совпадают',
      },
      tg:{
        login_button:'Войти через Telegram',
        phone:'Номер телефона',
        code:'Код из телеграмма',
      },
      dialog:'Этот сайт использует ваше местоположение и показывает его другим пользователям! Вы можете показывать свое местоположение только внутри групп доверенным людям! Точное местоположение будет только при использовании GPS! Сайт не передает ваши данные 3м лицам! Сайт не показывает рекламу и мы будем всегда рады вашим донатам! Сайт находится в разработке, прошу простить все недочеты.'
    },
    myprofile:{
      status:'Статус',
      site:'Сайт',
      site_title:'Если здесь указан сайт, он открывается, когда другие пользователи нажимают на ваш аватар на карте.',
      ava:'Загрузить аватар',
      delete_user:'Удалить юзера',
      delete_ava:'Удалить аватар',
      settings: {
        settings:'Настройки карты',
        centered: 'Центрировать меня на карте',
        open_site: 'Открывать сайт при клике на юзера',
      },
    },
    profile:{
      delete_user:'Удалить из избранных',
      add_user:'Добавить в избранные',
      on_map:'Увидеть на карте',
      to_site:'Перейти на сайт',
    },
    groups:{
      my_groups:'Мои группы',
      groups:'Группы',
      users:'Юзеры',
      attention:'Внимание!',
      dialog:'Поднимайте вверх свои диалоги с пользователями и чаты в приложении телеграмм если их не видете тут. Значит там давно не было активности и мы не можем получить их. Просто напишите в вашем чате или диалоге с пользователем. Спасибо за понимание!',
      add_group:'Добавить группу в mapa',
      del_group:'Удалить группу из mapa',
      exit_map:'Выйти из карты',
      invite:'Пригласить друга в mapa',
    }
  }
};

const locale = localStorage.mapa_locale!==undefined?localStorage.mapa_locale:'ru'
const i18n = new VueI18n({
  locale:locale,
  messages
});
export default i18n;


