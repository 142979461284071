import api from "./api"
import {hash} from "crypto";

const methods = {
  async getUserPhotos() {try {const photos = await api.call('photos.getUserPhotos', {user_id: {_: 'inputUserSelf'}});return photos} catch (error) {return null}},
  async getFullUser() {try {const user = await api.call('users.getFullUser', {id: {_: 'inputUserSelf'}});return user} catch (error) {return null}},
  async resolveUsername(username) {try {const user = await api.call("contacts.resolveUsername", { username });return user} catch (error) {return null}},
  async getFile(params){
    try{
      return await api.call("upload.getFile", params)
    }catch (err){
      // console.log(err)
      return null
  }},
  signIn({my_phone,code,phone_code_hash}){return api.call('auth.signIn',{phone_code:code,phone_number:my_phone,phone_code_hash:phone_code_hash})},
  signUp({phone,phone_code_hash}){return api.call('auth.signUp',{phone_number: phone,phone_code_hash:phone_code_hash,first_name:'MTProto',last_name:'Core'})},
  getPassword(){return api.call('account.getPassword')},
  checkPassword({ srp_id, A, M1 }) {return api.call('auth.checkPassword',{password:{_: 'inputCheckPasswordSRP', srp_id, A, M1}})},
  async sendCode(phone) {
    try{
      const hash = await api.call('auth.sendCode', {phone_number: phone, settings: {_: 'codeSettings',},})
      return hash
    }catch (e){
      console.log(e)
      return null
    }
  },
  compressImage(file, targetSize) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          // Создаем Canvas элемент
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Устанавливаем размеры для Canvas
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          // Сжимаем изображение
          let quality = 0.8; // Начальное качество сжатия
          let compressedDataUrl;

          // Сжимаем изображение, пока оно не станет меньше 512 КБ
          do {
            compressedDataUrl = canvas.toDataURL(file.type, quality);
            quality -= 0.1; // Понижаем качество
          } while (compressedDataUrl.length > targetSize && quality > 0.1);

          // Создаем File из сжатого Data URL
          fetch(compressedDataUrl)
            .then(res => res.arrayBuffer())
            .then(buffer => {
              const compressedFile = new File([buffer], file.name, {
                type: file.type,
                lastModified: Date.now()
              });
              resolve(compressedFile);
            });
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  },
  async sendMultiMedia(group, mediaList, caption) {
    console.log(group)
    console.log(mediaList)
    console.log(caption)
    try {
      if (group._ === 'channel') {
        await api.call('messages.sendMultiMedia', {
          peer: { _: 'inputPeerChannel', channel_id: group.id, access_hash: group.access_hash },
          multi_media: mediaList,
          message: caption, // Общее сообщение для всех медиафайлов
          random_id: Math.floor(Math.random() * 1000000),
        });
      } else if (group._ === 'chat') {
        console.log('chat')
        console.log(api)
        await api.call('messages.sendMultiMedia', {
          peer: { _: 'inputPeerChat', chat_id: group.id },
          multi_media: mediaList,
          // message: caption, // Общее сообщение для всех медиафайлов
          // random_id: Math.floor(Math.random() * 1000000),
        });
      }
      console.log('Медиафайлы успешно отправлены.');
    } catch (e) {
      console.error('Ошибка при отправке медиафайлов:', e);
    }
  },
  // async sendMultiMedia(group, mediaList, caption) {
  //   try {
  //     const peer = group._ === 'channel'
  //       ? { _: 'inputPeerChannel', channel_id: group.id, access_hash: group.access_hash }
  //       : { _: 'inputPeerChat', chat_id: group.id };
  //
  //     const result = await api.call('messages.sendMultiMedia', {
  //       peer: peer,
  //       multi_media: mediaList.map(media => ({
  //         _: 'inputSingleMedia',
  //         media: media.media,
  //         random_id: media.random_id,
  //       })),
  //       message: caption, // Общее сообщение для всех медиафайлов
  //       random_id: Math.floor(Math.random() * 1000000),
  //     });
  //
  //     console.log('Медиафайлы успешно отправлены:', result);
  //   } catch (e) {
  //     console.error('Ошибка при отправке медиафайлов:', e);
  //   }
  // }
  async sendMedia(group, file, caption) {
    // Отправка сообщения в зависимости от типа группы
    try {
      const peer = group._ === 'channel'
            ? { _: 'inputPeerChannel', channel_id: group.id, access_hash: group.access_hash }
            : { _: 'inputPeerChat', chat_id: group.id };

      const res = await api.call('messages.sendMedia', {
        peer: peer,
        media: { _: 'inputMediaUploadedPhoto', file: { _: 'inputFile', id: 0, parts: 1, name: file.name, md5_checksum: '' }},
        message: caption,
        random_id: Math.floor(Math.random() * 1000000),
      });
      console.log(res)
      //console.log(res.date)
      // localStorage.date = res.date
      // const id = res.updates[1].message.id
      // console.log(id)
      // const mes = await methods.getMessages(id)
      // console.log(mes)
      //await methods.getHistory(group.id)
      // return res.updates[1].message.media.photo
      return res
    } catch (e) {
      console.error('Ошибка при отправке сообщения:', e);
    }
  },

  async getMessages(id) {
    try {
      const message = await api.call("messages.getMessages", {
        id:[{ _: 'inputMessageID', id }]
      });
      return message
    }
    catch (error) {
      console.log(error)
      return null
    }
  },

  async getHistoryByDate(chatId,date) {
    try {
      const history = await api.call('messages.getHistory', {
        peer: { _: 'inputPeerChat', chat_id: chatId },
        limit: 50,
        offset_date: date+60,
      });
      return history
    } catch (error) {
      console.error("Ошибка при получении истории сообщений:", error);
    }
  },

}

export default methods;
