import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
const messages = {
  'en':{
    auth:{
      reg:'Registration',
      login:'Login',
      nick:'Nick',
      pass:'Password',
      pass_reg: 'Create Mapa account password',
      confirm:'Confirm',
      send:'Send',
      valid:{
        nick:'Enter nick',
        pass:'Enter password min. 8 characters',
        confirm:'Password mismatch',
      },
      tg:{
        login_button:'Sign in with Telegram',
        phone:'Phone number',
        code:'Code from telegram',
      },
      dialog_about_telegram:'When you log in via Telegram, you will be able to interact with your Telegram chats, add your chats to the Mapa and invite users, you will also be able to create markers with photos in groups. When you first log in via Telegram, we will create your profile that will be linked to your Telegram account, but you can also log in without Telegram, using your Telegram nickname and password that you set. We recommend using Telegram to access all of Mapa\'s features.',
      dialog_pass:'You register in the Mapa system. If you have problems accessing telegram, you can use your telegram nickname to log in, also create a password and remember it.',
      dialog:"This site uses your location and shows it to other users! You can show your location only within groups to trusted people! The exact location will only be when using GPS! Site does not transfer your data to 3rd parties! Site does not show ads and we will always welcome your donations! Site is under development, please forgive any shortcomings."
    },
    myprofile:{
      status:'Status',
      site:'Site',
      site_title:'If a site is listed here, it opens when other users click on your avatar on the map.',
      ava:'Download avatar',
      delete_user:'Delete user',
      delete_ava:'Delete avatar',
      settings:{
        settings:'Settings map',
        centered:'I\'m centered on the map',
        open_site:'Open user sites from the map',
      },
    },
    profile:{
      delete_user:'Delete from Featured',
      add_user:'Add to Featured',
      on_map:'View on map',
      to_site:'Go to site',
    },
    groups:{
      my_groups:'My groups',
      groups:'Groups',
      users:'Users',
      attention:'Attention!',
      dialog:'Raise your user dialogues and chats in the telegram app if you don\'t see them here. It means there hasn\'t been any activity there for a long time and we can\'t get them. Just write in your chat or user dialogue. Thank you for understanding!',
      add_group:'Add group to mapa',
      del_group:'Delete group from mapa',
      exit_map:'Exit the map',
      invite:'Invite a friend to mapa',
    },
    tg:{
      dialog_goToGlobalMap:'',
    }
  },
  'ru':{
    auth:{
      reg:'Регистрация',
      login:'Войти',
      nick:'Ник',
      pass: 'Пароль',
      pass_reg: 'Создайте пароль от аккаунта Mapa',
      confirm:'Подтверждение',
      send:'Отправить',
      valid:{
        nick:'Введите ник',
        pass:'Введите пароль мин. 8 символов',
        confirm:'Пароли не совпадают',
      },
      tg:{
        login_button:'Войти через Telegram',
        phone:'Номер телефона',
        code:'Код из телеграмма',
      },
      dialog_about_telegram:'При входе через телеграм вы сможете взаимодействовать со своими чатами телеграм, добавлять свои чаты в Mapa и приглашать пользователей, также вы сможете создавать маркеры с фото в группах. При первом входе через телеграм мы создадим ваш профиль который будет связан с вашей учетной записью телеграм, но так же вы сможете в него войте и без телеграм, посредством ника телеграм и пароля котрый вы зададите. Рекомендуем использовать телеграм для доступа ко всем возможностям Mapa.',
      dialog_pass:'Вы регистрируетесь в системе Mapa. Если возникнут проблемы с доступом к telegram вы сможете использовать для входа ваш ник telegram, создайте также пароль и запомните его.',
      dialog:'Этот сайт использует ваше местоположение и показывает его другим пользователям! Вы можете показывать свое местоположение только внутри групп доверенным людям! Точное местоположение будет только при использовании GPS! Сайт не передает ваши данные 3м лицам! Сайт не показывает рекламу и мы будем всегда рады вашим донатам! Сайт находится в разработке, прошу простить все недочеты.'
    },
    myprofile:{
      status:'Статус',
      site:'Сайт',
      site_title:'Если здесь указан сайт, он открывается, когда другие пользователи нажимают на ваш аватар на карте.',
      ava:'Загрузить аватар',
      delete_user:'Удалить юзера',
      delete_ava:'Удалить аватар',
      settings: {
        settings:'Настройки карты',
        centered: 'Центрировать меня на карте',
        open_site: 'Открывать сайт при клике на юзера',
      },
    },
    profile:{
      delete_user:'Удалить из избранных',
      add_user:'Добавить в избранные',
      on_map:'Увидеть на карте',
      to_site:'Перейти на сайт',
    },
    groups:{
      my_groups:'Мои группы',
      groups:'Группы',
      users:'Юзеры',
      attention:'Внимание!',
      dialog:'Поднимайте вверх свои диалоги с пользователями и чаты в приложении телеграмм если их не видете тут. Значит там давно не было активности и мы не можем получить их. Просто напишите в вашем чате или диалоге с пользователем. Спасибо за понимание!',
      add_group:'Добавить группу в mapa',
      del_group:'Удалить группу из mapa',
      exit_map:'Выйти из карты',
      invite:'Пригласить друга в mapa',
    },
    tg:{
      dialog_goToGlobalMap:'Перейти на глобальную карту?',
    }
  }
};

const locale = localStorage.mapa_locale!==undefined?localStorage.mapa_locale:'ru'
const i18n = new VueI18n({
  locale:locale,
  messages
});
export default i18n;


