<template>
<div class="main">

  <div class="toggle_groups">
    <Button :style="state===0?{'background': '#4a76a8'}:{}" class="p-button-secondary p-button-sm" @click="change(0)">{{$t('groups.my_groups')}}</Button>
    <Button :style="state===1?{'background': '#4a76a8'}:{}" class="p-button-secondary p-button-sm" @click="change(1)">{{$t('groups.groups')}}</Button>
    <Button :style="state===2?{'background': '#4a76a8'}:{}" class="p-button-secondary p-button-sm" @click="change(2)">{{$t('groups.users')}}</Button>
    <Button class="p-button-secondary p-button-sm" @click="confirm=true">⚠️</Button>
  </div>

  <div v-if="!loading">
    <div v-if="state===0" @scroll="onScroll(tgDialogStore.my_groups)" class="dialog-list">
      <div v-for="group of tgDialogStore.my_groups" :key="group.id" :id="'group-' + group.id">
        <div class="user-item">
          <div @click="activateMap(group)" class="user-item-container">
<!--            <img v-if="group.photoLoaded" :src="group.photo_url" alt="User Photo" class="user-photo">-->
            <img :src="group.photo_url" alt="User Photo" class="user-photo">
            <div class="user-nick" :style="handleColor(group)">{{ group.title }}</div>
            <Button @click.stop="settings_toggle($event, group.id)" class="p-button-danger p-button-rounded p-button-sm p-button-raised delete-button">🛠️</Button>
            <OverlayPanel :ref="'settings'+group.id" :target="'settings'+group.id" appendTo="body" class="settings_menu">
              <p v-if="!group.mapa" class="select" @click="addGroup(group)">{{$t('groups.add_group')}}</p>
              <p v-if="group.mapa" class="select" @click="delGroup(group)">{{$t('groups.del_group')}}</p>
              <p v-if="group.id===room" class="select" @click="deactivateMap(group.id)">{{$t('groups.exit_map')}}</p>
            </OverlayPanel>
          </div>
        </div>
      </div>
    </div>

    <div v-if="state===1" @scroll="onScroll(tgDialogStore.all_groups)" class="dialog-list">
      <div v-for="group of tgDialogStore.all_groups" :key="group.id" :id="'group-' + group.id">
        <div class="user-item">
          <div @click="activateMap(group)" class="user-item-container">
            <img :src="group.photo_url" alt="User Photo" class="user-photo">
            <div class="user-nick" :style="handleColor(group)">{{ group.title }}</div>
            <Button @click.stop="settings_toggle($event, group.id)" class="p-button-danger p-button-rounded p-button-sm p-button-raised delete-button">🛠️</Button>
            <OverlayPanel :ref="'settings'+group.id" :target="'settings'+group.id" appendTo="body" class="settings_menu">
              <p v-if="group.id===room" class="select" @click="deactivateMap(group.id)">{{$t('groups.exit_map')}}</p>
            </OverlayPanel>
          </div>
        </div>
      </div>
    </div>

    <div v-if="state===2" @scroll="onScroll(tgDialogStore.users)" class="dialog-list">
      <div v-for="user of tgDialogStore.users" :key="user.id" :id="'group-' + user.id">
        <div class="user-item">
          <div @click="activateMap(user)" class="user-item-container">
            <img :src="user.photo_url" alt="User Photo" class="user-photo">
            <div class="user-nick" :style="handleColor(user)">{{ user.first_name }}</div>
            <Button @click.stop="settings_toggle($event, user.id)" class="p-button-danger p-button-rounded p-button-sm p-button-raised delete-button">🛠️</Button>
            <OverlayPanel :ref="'settings'+user.id" :target="'settings'+user.id" appendTo="body" class="settings_menu">
              <p v-if="!user.mapa" class="select" @click="inviteUser(user)">{{$t('groups.invite')}}</p>
              <p v-if="room&&room.includes(user.id)" class="select" @click="deactivateMap(user.id)">{{$t('groups.exit_map')}}</p>
            </OverlayPanel>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div v-else class="zero_data">
    <ProgressSpinner />
  </div>

  <Dialog :header="$t('groups.attention')" :visible.sync="confirm" :modal="true">
    {{$t('groups.dialog')}}
    <template #footer>
      <Button label="ОК" autofocus @click="confirm=false"/>
    </template>
  </Dialog>

</div>
</template>

<script>
import DataView from 'primevue/dataview'
import ProgressSpinner from 'primevue/progressspinner';
import tg from '../telegram/tg'
import {TgDialogsStore, TgMapStore} from "../store";
import OverlayPanel from "primevue/overlaypanel";
import {integer} from "@vuelidate/validators";
import Dialog from 'primevue/dialog';
import {socket} from "../socket";
export default {
  name: "groups",
  components:{DataView, ProgressSpinner, OverlayPanel, Dialog},
  setup() {
    const tgDialogStore = TgDialogsStore()
    const tgMapStore = TgMapStore()
    return {tgDialogStore,tgMapStore}

  },
  data(){
    return{
      loading:null,
      state:localStorage.group_state!=undefined?Number(localStorage.group_state):2,
      confirm: false,
      room:localStorage.group_map_id!==undefined ? localStorage.group_map_id:null,
    }
  },
  mounted() {
    if(this.tgDialogStore.all_groups.length===0){
      this.start()
    }
  },
  methods:{
    items(){
      if(this.state === 0)
        return this.tgDialogStore.my_groups
      else if(this.state === 1)
        return this.tgDialogStore.all_groups
      else
        return this.tgDialogStore.users
    },
    changeState(state){
      this.state=state
      localStorage.group_state=state
    },
    async change(state){
      await this.changeState(state)
      this.onScroll(this.items())
    },
    async start(){
      await this.getDialogs()
      this.onScroll(this.items())
    },
    async onScroll(items) {
      const visibleGroups = items.filter(group => this.isInViewport(group));
      for (const group of visibleGroups) {
        if (!group.photoLoaded) {
          this.$set(group, 'photoLoaded', true);
          const photoUrl = await tg.getChannelOrUserPhoto(group)
          this.$set(group, 'photo_url', photoUrl);
        }
      }
    },
    isInViewport(group) {
      const element = document.getElementById(`group-${group.id}`);
      if (!element) return false;
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    handleColor(data){
      if(this.room!==null&&this.room.includes(data.id)){
        return {'color': 'red'}
      }else if(data.mapa===true){
        return {'color': '#4a76a8'}
      }
    },
    async checkGroups(ids){
      const res = await this.$http.post(localStorage.mapa_api+'/tg/groups/', {nick:localStorage.mapa_me, ids:ids, check_groups:'OK'})
      return res.data
    },
    addGroup(group){
      this.$http.post(localStorage.mapa_api+'/tg/groups/', {nick:localStorage.mapa_me, group_id:group.id, add_group:'OK'}).then((res) => {
        if(res.ok===true){
          group.mapa=true
        }
      });
    },
    delGroup(group){
      this.$http.post(localStorage.mapa_api+'/tg/groups/', {nick:localStorage.mapa_me, group_id:group.id, del_group:'OK'}).then((res) => {
        if(res.ok===true){
          group.mapa=false
          if(this.room===group.id){
            this.room=null
            localStorage.removeItem('group_map_id')
          }
        }
      });
    },
    inviteUser(user){
      tg.sendMessage(user.id, user.access_hash, 'Привет! Приглашаю тебя в mapa.ws!')
    },
    settings_toggle(e, id){
      this.$refs['settings'+id][0].toggle(e)
    },
    async getDialogs(){
      this.loading = true
      let res = await tg.getDialogs()
      console.log(res)

      const all_groups = res.chats.filter(chat => chat._ !== 'chatForbidden')
      const ids = all_groups.map(obj => obj.id)
      const mapa_groups = await this.checkGroups(ids)

      for (const group of all_groups){
        group.mapa = false
        // group.photo.url = await tg.getChannelPhoto(group)
        group.photo_url = 'default.jpg'
        for (const item of mapa_groups){
          if(group.id==item.group_id){
            group.mapa = true
          }
        }
      }

      await this.tgDialogStore.add_my_groups(all_groups.filter(chat => chat.creator === true))
      await this.tgDialogStore.add_all_groups(all_groups.filter(chat => chat.creator === false))

      const users = res.users.filter(user=>user.deleted === false&&user.username!==localStorage.mapa_me)
      for (const user of users){
        user.photo_url = 'default.jpg'
      }
      this.tgDialogStore.add_users(users)
      this.loading = false
    },
    async activateMap(data){
      console.log(data)
      const t = this
      function addRoom(id){
        t.room = id
        localStorage.group_map_id = id
        // socket.onclose = function(event) {
        //   if (event.wasClean) {
        //     console.log(`Соединение закрыто чисто, код=${event.code} причина=${event.reason}`);
        //   }else {
        //     console.log('Соединение прервано')
        //   }
        // };
        t.tgMapStore.cleanUsers()
      }
      if(data.mapa===true){
        await addRoom(data.id)
        // t.$emit('group_map')
        window.location.reload()
      } else if(data._==='user'){
        console.log('user')
        const me = await tg.getMe()
        const list = [data.id, me.id].sort((x, y) => x - y)
        await addRoom(list[0]+list[1])
        window.location.reload()
      }
    },
    deactivateMap(id){
      this.room=null
      localStorage.removeItem('group_map_id')
      // socket.close()
      // socket.onclose = function(event) {
      //   if (event.wasClean) {
      //     console.log(`Соединение закрыто чисто, код=${event.code} причина=${event.reason}`);
      //   }else {
      //     console.log('Соединение прервано')
      //   }
      // }
      this.tgMapStore.cleanUsers()
      window.location.reload()
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  }
}
</script>

<style scoped>
.toggle_groups{
  text-align: center;
  margin: 5px auto;
}
.dialog-list {
  overflow-y: auto;
  height: calc(100% - 105px);
}


</style>
